import {getFormConfig} from "./formConfig";

export const getTableColumn = vm => {
  const fixedList = [
    { type: 'seq', title: '序号', width: '60', tag: 'seq' },
  ];

  const list = [
    {
      field: "GROUP_ID",
      title: "GROUP_ID",
      tag:'GROUP_ID'
    },
    {
      field: "NOTES",
      title: "NOTES",
      tag:'NOTES'
    },
  ]
  const opList = [
    {
      field: "edit",
      title: "操作",
      fixed: 'right',
      width: '200',
      tag: 'edit',
      _renderType: true,
      render: (h, {data}) => {
        let { row } = data;
        return (
          <div class={'edit'}>
            <el-button type="text" onClick={() => { vm.openModal('editRole',row) }}>编辑</el-button>
          </div>
        );
      }
    },
  ]
  return {
    tableColumn:[...fixedList ,...list , ...opList]
  }
}