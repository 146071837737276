import Api from '@api';
import {getRoleLists, getRoleMenus, getRoleResources, updateRoleResources} from "../../../../../api/auth";

export default {
  name: 'editRole',
  components: {
  },
  props: {
    injectData: {
      type: Object,
      default: () => {
        return {
        }
      }
    },
  },
  data() {
    return {
      form:{
        GROUP_ID:'',
      },
      data: [],
      defaultProps: {
        children: 'SUBMENUS',
        label: 'NAME'
      },
      tableData:[],
      checkedMenuKeys:[],
      permissionKeys:[],
    }
  },
  mounted(){
    this.$nextTick(()=>{
      this.form.GROUP_ID = JSON.parse(JSON.stringify(this.injectData)).GROUP_ID
      this.getRoleData()
    })
  },
  methods: {
    handleChange(row){

    },
    async getRoleData(){
      const params = {
        ROLE_ID:this.injectData.GROUP_ID
      }
      const {data,status,message} = await getRoleResources(params)
      if(status === 200){
        this.getMenuData({SUBMENUS:data})
        this.data = JSON.parse(JSON.stringify(data))
        this.setCheckedKeys();
      }
    },
    /*
    * 菜单数据初始化
    * */
    getMenuData(data){
      if(!Array.isArray(data.SUBMENUS)) return;
      if(!data.SUBMENUS.length) return;
      //权限点数据转化
      let isPOINT = data.SUBMENUS.every(item=>item.TYPE === 'POINT')
      if(isPOINT){
        data.PERMISSION_LIST = JSON.parse(JSON.stringify(data.SUBMENUS))
        delete data.SUBMENUS
        return;
      }
      //获取已经选择菜单ID   只获取叶子节点的父级菜单
      data.SUBMENUS.forEach((item,index)=>{
        if(item.TYPE === 'MENU'){
          if(item.PERMISSION_TAG){
            if(Array.isArray(item.SUBMENUS) && item.SUBMENUS.every(item=>item.TYPE === 'POINT')){
              this.checkedMenuKeys.push(item.ID)
            }
            if(!item.SUBMENUS){
              this.checkedMenuKeys.push(item.ID)
            }
          }
        }
        this.getMenuData(item)
      })
    },
    getPermissionKeys(data){
      if(data && Array.isArray(data.SUBMENUS)){
        data.SUBMENUS.forEach((item,index)=>{
          if(Array.isArray(item.PERMISSION_LIST)){
            item.PERMISSION_LIST.forEach(child=>{
              if(child.PERMISSION_TAG){
                this.permissionKeys.push(child.ID)
              }
            })
          }
          this.getPermissionKeys(item)
        })
      }
    },
    setCheckedKeys(){
      this.$refs.tree.setCheckedKeys(this.checkedMenuKeys)
    },
    getCheckedKeys() {
      return this.$refs.tree.getCheckedKeys()
    },
    getHalfCheckedKeys(){
      return this.$refs.tree.getHalfCheckedKeys()
    },
    async saveMenuData() {
      const checkedKeys = this.getCheckedKeys() || []
      const halfCheckedKeys = this.getHalfCheckedKeys() || []
      this.getPermissionKeys({SUBMENUS:this.data})
      const params = {
        ROLE_ID:this.injectData.GROUP_ID,
        RESOURCE_IDS:[...checkedKeys,...halfCheckedKeys,...this.permissionKeys]
      }
      const {data,status,message} = await updateRoleResources(params)
      if(status === 200){
        this.$emit('close')
      }
    },
    async handleConfirm(){
      this.saveMenuData()
    },
    handleClose(params = {}) {
      this.$emit('close', params)
    }
  }
}