import Api from '@api';
import {getFormConfig} from "./configs/formConfig";
import {getTableColumn} from './configs/tableConfig';
import {getRoleLists, getUserRoleResources} from "../../../api/auth";
import formTableInitMixins from '@/mixins/formTableInit.js';
import habitMixins from '@/mixins/habit.js';
import editRole from "./blocks/editRole/editRole.vue";

export default {
  name: 'roleManage',
  components: {
    editRole
  },
  mixins: [habitMixins,formTableInitMixins],
  data: function () {
    const formConfig = getFormConfig(this).filterFormConfig;
    const tableColumn = getTableColumn(this).tableColumn;

    return {
      formData: {}, //筛选条件
      filterFormConfig:formConfig,
      conditions: formConfig, // 增加查询条件的值
      importData: [], // table 数据
      tableColumn, // 原本的表格配置
      tableColumnConfigList:tableColumn,
      dialog: null,
      injectData: {},
      modal:null
    }
  },
  mounted() {
    this.$nextTick(()=>{
      this.getList();
    })
  },
  methods: {

    /**
     * @description 初始化数据
     */
    async getList() {
      const params = {
        ...this.$refs.filterForm.formData,
        PAGE_NUM: await this.$refs.configTable.page.currentPage,
        PAGE_SIZE: await this.$refs.configTable.page.pageSize
      }
      const {data,status,message} = await getRoleLists(params)
      if(status === 200){
        this.importData = data.list
        this.$refs.configTable.page.total = Number(data.total)
      }
    },
    /*
    * @param modal  模态框名称
    * @param params  参数
    * */
    openModal(modal, params){
      this.modal = modal
      this.injectData = params
    },
    close(){
      this.modal = null
    },
    refresh(){
      this.getList();
    }
  }
}