// import Api from '../../wxUserManage/blocks/editUser/node_modules/@api';

export const getFormConfig = (vm) => {
    return {
      filterFormConfig:[
        {
          "key": "GROUP_ID",
          "label": "GROUP_ID",
          "type": "input",
          "placeholder": "GROUP_ID",
          "checked": false
        },
      ]
    }
}

