import { render, staticRenderFns } from "./editRole.vue?vue&type=template&id=29a1ee46&scoped=true&"
import script from "./editRole.js?vue&type=script&lang=js&"
export * from "./editRole.js?vue&type=script&lang=js&"
import style0 from "./editRole.scss?vue&type=style&index=0&id=29a1ee46&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "29a1ee46",
  null
  
)

export default component.exports